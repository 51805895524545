var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_fifo_report") } },
    [
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "label-col": { span: 8 },
            "wrapper-col": { span: 14 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validateForm("list")
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_part_category"),
                        prop: "productCategory"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            showSearch: true,
                            allowClear: true,
                            loading: _vm.categoryList.fetching,
                            "filter-option": false
                          },
                          on: {
                            search: _vm.handleSearchCategory,
                            change: _vm.handleCategoryProductChange
                          },
                          model: {
                            value: _vm.form.productCategory,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "productCategory", $$v)
                            },
                            expression: "form.productCategory"
                          }
                        },
                        _vm._l(_vm.categoryList.data, function(cat, index) {
                          return _c(
                            "a-select-option",
                            {
                              key: cat.name + "~" + index,
                              attrs: { value: cat.id }
                            },
                            [_vm._v(" " + _vm._s(cat.name) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_part_number"),
                        prop: "productCode"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            showSearch: true,
                            allowClear: true,
                            "filter-option": false
                          },
                          on: {
                            search: _vm.handleSearchProduct,
                            change: _vm.onChangeProductCode
                          },
                          model: {
                            value: _vm.form.productCode,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "productCode", $$v)
                            },
                            expression: "form.productCode"
                          }
                        },
                        _vm._l(_vm.productList.data, function(product, index) {
                          return _c(
                            "a-select-option",
                            {
                              key: product.name + "~" + index,
                              attrs: { value: product.id }
                            },
                            [_vm._v(" " + _vm._s(product.code) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_part_name"),
                        prop: "productName"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.productName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "productName", $$v)
                          },
                          expression: "form.productName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_from_date"),
                        prop: "fromDate"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: _vm.DEFAULT_DATE_FORMAT,
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "disabled-date": _vm.disableStartDate
                        },
                        model: {
                          value: _vm.form.fromDate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "fromDate", $$v)
                          },
                          expression: "form.fromDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_to_date"), prop: "toDate" } },
                    [
                      _c("a-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: _vm.DEFAULT_DATE_FORMAT,
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "disabled-date": _vm.disableEndDate
                        },
                        model: {
                          value: _vm.form.toDate,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "toDate", $$v)
                          },
                          expression: "form.toDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_branch"), prop: "branch" } },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            name: "branch",
                            placeholder: _vm.$t("lbl_branch_placeholder"),
                            showSearch: true,
                            allowClear: true,
                            "filter-option": false
                          },
                          on: {
                            search: function(value) {
                              return _vm.getBranch(value)
                            }
                          },
                          model: {
                            value: _vm.form.branch,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "branch", $$v)
                            },
                            expression: "form.branch"
                          }
                        },
                        _vm._l(_vm.dataBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_warehouse"),
                        prop: "warehouse"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            showSearch: true,
                            allowClear: true,
                            "filter-option": false
                          },
                          on: {
                            search: _vm.handleSearchWarehouse,
                            change: _vm.handleWarehouseChange
                          },
                          model: {
                            value: _vm.form.warehouse,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "warehouse", $$v)
                            },
                            expression: "form.warehouse"
                          }
                        },
                        _vm._l(_vm.warehouseList.data, function(
                          warehouse,
                          index
                        ) {
                          return _c(
                            "a-select-option",
                            {
                              key: warehouse.label + "~" + index,
                              attrs: { value: warehouse.value }
                            },
                            [_vm._v(" " + _vm._s(warehouse.label) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.resetForm } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            "html-type": "submit",
                            loading: _vm.loading.find,
                            type: "primary"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          size: "small",
          loading: _vm.loading.find,
          "data-source": _vm.dataSource,
          columns: _vm.tableColumns,
          scroll: { x: "calc(100% + 300px)", y: 700 },
          pagination: {
            showTotal: function(total) {
              return _vm.$t("lbl_total_items", { total: total })
            },
            showSizeChanger: true,
            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS
          }
        },
        scopedSlots: _vm._u([
          {
            key: "currency",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("currency")(text)))])
            }
          },
          {
            key: "number",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
            }
          },
          {
            key: "date",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
            }
          },
          {
            key: "nullable",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
            }
          },
          {
            key: "expandedRowRender",
            fn: function(record) {
              return [
                _c(
                  "a-row",
                  { attrs: { gutter: [16, 16] } },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "a-table",
                          {
                            attrs: {
                              size: "small",
                              "data-source": record.cogsIn || [],
                              columns: _vm.columnCogsIn,
                              "row-key": function(r, i) {
                                return i
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "currency",
                                  fn: function(text) {
                                    return _c("span", {}, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm._f("currency")(text)) +
                                          " "
                                      )
                                    ])
                                  }
                                },
                                {
                                  key: "number",
                                  fn: function(text) {
                                    return _c("span", {}, [
                                      _vm._v(_vm._s(_vm._f("qty")(text)))
                                    ])
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("lbl_cogs_in")) + " "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "a-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "a-table",
                          {
                            attrs: {
                              size: "small",
                              "data-source": record.cogsOut || [],
                              columns: _vm.columnCogsOut,
                              "row-key": function(r, i) {
                                return i
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "currency",
                                  fn: function(text) {
                                    return _c("span", {}, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm._f("currency")(text)) +
                                          " "
                                      )
                                    ])
                                  }
                                },
                                {
                                  key: "number",
                                  fn: function(text) {
                                    return _c("span", {}, [
                                      _vm._v(_vm._s(_vm._f("qty")(text)))
                                    ])
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("lbl_cogs_out")) + " "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-row",
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "a-table",
                          {
                            attrs: {
                              size: "small",
                              "data-source": record.balance || [],
                              columns: _vm.columnBalance,
                              "row-key": function(r, i) {
                                return i
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "currency",
                                  fn: function(text) {
                                    return _c("span", {}, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm._f("currency")(text)) +
                                          " "
                                      )
                                    ])
                                  }
                                },
                                {
                                  key: "number",
                                  fn: function(text) {
                                    return _c("span", {}, [
                                      _vm._v(_vm._s(_vm._f("qty")(text)))
                                    ])
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("lbl_balance")) + " "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "a-descriptions",
                  { attrs: { size: "small", column: 2 } },
                  [
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_grand_total_qty_in") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("qty")(_vm.dataReport.grandTotalQtyIn)
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_grand_total_qty_out") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("qty")(_vm.dataReport.grandTotalQtyOut)
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_grand_total_in") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.dataReport.grandTotalIn)
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_grand_total_out") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.dataReport.grandTotalOut)
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      {
                        attrs: { label: _vm.$t("lbl_grand_total_qty_balance") }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.dataReport.grandTotalQtyBalance
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_grand_total_balance") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.dataReport.grandTotalBalance
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "mt-2 text-right" },
        [
          _c(
            "a-button",
            {
              attrs: {
                type: "primary",
                icon: "download",
                loading: _vm.loading.download
              },
              on: {
                click: function($event) {
                  return _vm.validateForm("download")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }