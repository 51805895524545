
































































































































































































































import { SearchBuilder } from "@/builder";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { debounceProcess } from "@/helpers/debounce";
import useBlob from "@/hooks/useBlob";
import useDate from "@/hooks/useDate";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ONE, PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import {
  DEFAULT_DATE_FORMAT
} from "@/models/constants/date.constant";
import {
  DataListAssetBook,
  DataResponseCalendar,
  ResponseAssetRetirement,
} from "@/models/interface/assets.interface";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { assetsServices } from "@/services/assets.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import printJs from "print-js";
import Vue from "vue";

interface DataListAssetRetired {
  assetNumber: string;
  unitCode: string;
  serialNumber: string;
}

export default Vue.extend({
  name: "AssetRetirementReport",
  data() {
    this.getListAssetBook = debounceProcess(this.getListAssetBook);
    this.getListCalendar = debounceProcess(this.getListCalendar);
    this.getBranch = debounceProcess(this.getBranch);
    return {
      PAGE_SIZE_OPTIONS,
      DEFAULT_DATE_FORMAT,
      form: this.$form.createForm(this, { name: "AssetRetirementReport" }),
      dataCalendar: [] as DataResponseCalendar[],
      dataListBook: [] as DataListAssetBook[],
      dataListAssetRetired: [] as DataListAssetRetired[],
      dataListRetiredType: [] as ResponseListMaster[],
      dataBranch: [] as DataWarehouseBranch[],
      formRules: {
        book: {
          label: "lbl_book",
          name: "book",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["book"],
        },
        unitCode: {
          label: "lbl_unit_code",
          name: "unitCode",
          placeholder: "lbl_unit_code_placeholder",
          decorator: ["unitCode"],
        },
        serialNumber: {
          label: "lbl_serial_number",
          name: "serialNumber",
          placeholder: "lbl_serial_number_placeholder",
          decorator: ["serialNumber"],
        },
        periode: {
          label: "lbl_period",
          name: "periode",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["periode"],
        },
        branch: {
          label: "lbl_branch",
          name: "branch",
          placeholder: "Insert Branch",
          decorator: ["branch"],
        },
        retiredType: {
          label: "lbl_retired_type",
          name: "retiredType",
          placeholder: "lbl_retired_type_placeholder",
          decorator: ["retiredType"],
        },
        retirementDate: {
          label: "lbl_retirement_date",
          name: "retireDate",
          placeholder: "lbl_retirement_date_placeholder",
          decorator: ["retireDate"],
        },
      },
      columnsTable: [
        {
          title: this.$t("lbl_asset_number"),
          dataIndex: "assetNumber",
          key: "assetNumber",
          width: 150,
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          width: 150,
        },
        {
          title: this.$t("lbl_equipment"),
          dataIndex: "equipment",
          key: "equipment",
          width: 150,
        },
        {
          title: this.$t("lbl_merk"),
          dataIndex: "merk",
          key: "merk",
          width: 150,
        },
        {
          title: this.$t("lbl_type"),
          dataIndex: "type",
          key: "type",
          width: 150,
        },
        {
          title: this.$t("lbl_sn"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: 150,
        },
        {
          title: this.$t("lbl_spec"),
          dataIndex: "spec",
          key: "spec",
          width: 150,
        },
        {
          title: this.$t("lbl_cost"),
          dataIndex: "cost",
          key: "cost",
          width: 100,
          customRender: text => currencyFilter(text),
        },
        {
          title: this.$t("lbl_asset_book"),
          dataIndex: "assetBook",
          key: "assetBooks",
          width: 150,
        },
        {
          title: this.$t("lbl_retirement_type"),
          dataIndex: "assetRetirementType",
          key: "retirementType",
          width: 120,
        },
        {
          title: this.$t("lbl_retirement_date"),
          dataIndex: "retireDate",
          key: "retireDate",
          width: 120,
          customRender: text => dateFormat(text),
        },
        {
          title: this.$t("lbl_gain_loss"),
          dataIndex: "gainLossAmount",
          key: "gainLoss",
          width: 120,
          customRender: text => currencyFilter(text),
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
          width: 150,
        },
        {
          title: this.$t("lbl_warehouse"),
          dataIndex: "warehouseName",
          key: "warehouseName",
          width: 150,
        },
      ],
      modal: {
        visible: false,
        toggle(): void {
          this.visible = !this.visible;
        },
      },
      loading: {
        print: false,
        download: false,
        find: false,
        retiredType: false,
        branch: false,
        periode: false,
        assetRetired: false,
        book: false,
      },
      dataReport: {
        currentPage: 0,
        data: [],
        totalElements: 0,
        totalPages: 0,
      } as ResponseAssetRetirement,
      pagination: {
        limit: 10,
        page: ONE,
      },
    };
  },
  mounted() {
    this.getListAssetBook("");
    this.getListCalendar("");
    this.getBranch("");
    this.getListAssetRetired("", "unitCode");
    this.getListRetiredType();
  },
  methods: {
    handleDownload(): void {
      const { toDownload } = useBlob();
      const field = this.form.getFieldsValue();
      const params = new RequestQueryParams();
      params.page = 0;
      params.limit = this.dataReport.totalElements;
      params.search = this.buildQuery(field);
      params.params = this.buildParamsReport(field);
      params.sorts = "createdDate:desc";
      params.period = field.periode;
      this.loading.download = true;
      assetsServices
        .getDownloadReportAssetRetirement(params)
        .then(data => {
          toDownload(data, "reports_asset_retirement.xlsx");
        })
        .finally(() => (this.loading.download = false));
    },
    handlePrint(): void {
      const { toObjectUrl } = useBlob();
      const field = this.form.getFieldsValue();
      const params = new RequestQueryParams();
      params.search = this.buildQuery(field);
      params.params = this.buildParamsReport(field);
      params.limit = this.dataReport.totalElements;
      params.page = 0;
      params.period = field.periode;

      this.loading.print = true;
      assetsServices
        .getPrintReportAssetRetirement(params)
        .then(data => {
          printJs(toObjectUrl(data));
        })
        .finally(() => (this.loading.print = false));
    },
    getBranch(valueSearch: string): void {
      const params = new RequestQueryParams();
      if (valueSearch) {
        params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
      }
      this.loading.branch = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(response => {
          this.dataBranch = response.data;
        })
        .finally(() => (this.loading.branch = false));
    },
    getListCalendar(valueSearch: string | undefined) {
      const params = new RequestQueryParams();
      if (valueSearch) {
        params.search = `month~*${valueSearch}*_OR_year~*${valueSearch}*`;
      }
      this.loading.periode = true;
      assetsServices
        .listCalendar(params)
        .then(data => {
          this.dataCalendar = data.data.filter(
            item => !item.month.includes("Adj")
          );
        })
        .finally(() => (this.loading.periode = false));
    },
    getListAssetBook(valueSearch: string): void {
      const params = new RequestQueryParams("type~commercial_OR_active~true");
      if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
      this.loading.book = true;
      assetsServices
        .listAssetBook(params)
        .then(data => {
          this.dataListBook = data.data;
        })
        .finally(() => (this.loading.book = false));
    },
    getReport(params: RequestQueryParams): void {
      this.loading.find = true;
      assetsServices
        .getReportAssetRetirementV2(params)
        .then(res => {
          this.dataReport = res;
        })
        .finally(() => {
          this.loading.find = false;
        });
    },
    submitForm(): void {
      const field = this.form.getFieldsValue();
      const params = new RequestQueryParams();
      params.search = this.buildQuery(field);
      params.limit = this.pagination.limit;
      params.page = this.pagination.page - ONE;
      params.sorts = "createdDate:desc";
      params.period = field.periode;
      this.modal.visible = false;
      this.getReport(params);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getListRetiredType(): void {
      this.loading.retiredType = true;
      masterServices
        .listMaster({ name: "ASSET_RETIREMENT_TYPE" })
        .then(data => {
          this.dataListRetiredType = data;
        })
        .finally(() => (this.loading.retiredType = false));
    },
    getListAssetRetired(
      value: string,
      type: "unitCode" | "serialNumber"
    ): void {
      const params = new RequestQueryParams();
      if (value && type === "unitCode") {
        this.form.setFieldsValue({ serialNumber: "" });
        params.search = `unitCode~*${value}*`;
      } else if (value && type === "serialNumber") {
        params.search = `serialNumber~*${value}*`;
      }
      this.loading.assetRetired = true;
      assetsServices
        .getReportAssetRetirementV2(params)
        .then(data => {
          this.dataListAssetRetired = data.data.map<DataListAssetRetired>(
            valueObject => {
              return {
                assetNumber: valueObject.assetNumber,
                unitCode: valueObject.unitCode,
                serialNumber: valueObject.serialNumber,
              };
            }
          );
          this.dataListAssetRetired = this.dataListAssetRetired.filter(
            (dataFilter, index, self) =>
              index === self.findIndex(t => t.unitCode === dataFilter.unitCode)
          );
        })
        .finally(() => (this.loading.assetRetired = false));
    },
    handleChangeUnitCode(value: string | undefined) {
      this.getListAssetRetired(value || "", "unitCode");
    },
    handleChangeSerialNumber(value: string | undefined) {
      this.getListAssetRetired(value || "", "serialNumber");
    },
    buildQuery(field): string {
      const query: Array<string> = [];
      const builder = new SearchBuilder();
      const { toStartDay, toEndDay } = useDate();

      if (field.book) {
        query.push(builder.push(["assetBookId", field.book]).build());
      }

      if (field.branch) {
        query.push(
          builder
            .push([
              "branchId",
              field.branch,
            ])
            .build()
        );
      }

      if (field.unitCode) {
        query.push(builder.push(["unitCode", field.unitCode]).build());
      }

      if (field.serialNumber) {
        query.push(
          builder.push(["serialNumber", field.serialNumber]).build()
        );
      }

      if (field.retiredType) {
        query.push(builder.push(["assetRetirementType", field.retiredType]).build());
      }

      if (field.retireDate && field.retireDate.length === 2) {
        const [start, end] = field.retireDate;
        const startDate = toStartDay(start).format();
        const endDate = toEndDay(end).format();
        
        query.push(
          builder
            .push(["retireDate", startDate], { het: true })
            .and()
            .push(["retireDate", endDate], { let: true })
            .build()
        );
      }

      return query.join(builder.AND);
    },
    onChangeTable(pagination: {
      total: number;
      current: number;
      pageSize: number;
    }): void {
      this.pagination.page = pagination.current;

      if (this.pagination.limit !== pagination.pageSize) {
        this.pagination.page = ONE;
      }

      this.pagination.limit = pagination.pageSize;

      this.submitForm();
    },
    buildParamsReport(field): string {
      const company: string =
        this.$store.state.authStore.authData.companyName || "ALL";
      const branch =
        this.dataBranch.find(e => e.id === field.branch)?.name || "ALL";
      const book =
        this.dataListBook.find(e => e.id === field.book)?.name || "ALL";
      const periode = field.periode || "ALL";
      const retiredType = field.retiredType || "ALL";

      const params: Array<string> = [
        company,
        branch,
        book,
        periode,
        retiredType,
      ];

      return params.join(",");
    },
  },
});
